
/* Table */
/* ------------------------------------------- */

.table-header {
    background-color: #f8f8f8;
}

@media (max-width: 768px) {

    .table-salary {
        &.table-bordered td, &.table-bordered th { border-bottom: 0; }
        .table-header th,
        td { 
            display: flex;
            width: 100%;
        }
        .table-degree {
            align-items: center;
            padding-left: 20px;
            font-weight: bold;
            background-color: #f8f8f8;
            &::before { content: none; }
        }

        td { 
            padding: 0;
            &::before {
                content: attr(data-label);
                flex: 0 0 150px;
                padding: 8px 15px;
                text-align: right;
                font-weight: bold;
                background-color: #f8f8f8;
            }
        }

        span { 
            flex: 1;
            padding: 8px 15px;
        }
    }

    .table-year-xp {
        display: none;
    }
}