
/* Font */
/* ------------------------------------------- */
@font-face{
    font-family: "Rubik";
    src:url('../fonts/rubik-black.woff2') format('woff2'),
        url('../fonts/rubik-black.woff') format('woff');
    font-weight: 900;
    font-style: normal;
}
@font-face{
    font-family: "Rubik";
    src:url('../fonts/rubik-bold.woff2') format('woff2'),
        url('../fonts/rubik-bold.woff') format('woff');
    font-weight: bold;
    font-style: normal;
}
@font-face{
    font-family: "Rubik";
    src:url('../fonts/rubik-medium.woff2') format('woff2'),
        url('../fonts/rubik-medium.woff') format('woff');
    font-weight: 300;
    font-style: normal;
}
@font-face{
    font-family: "Rubik";
    src:url('../fonts/rubik-regular.woff2') format('woff2'),
        url('../fonts/rubik-regular.woff') format('woff');
    font-weight: normal;
    font-style: normal;
}
@font-face{
    font-family: "Rubik";
    src:url('../fonts/rubik-lightitalic.woff2') format('woff2'),
        url('../fonts/rubik-lightitalic.woff') format('woff');
    font-weight: 100;
    font-style: italic;
}
