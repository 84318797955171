
	
	
.page-content {
	
	.page-container {
	
		width: 100%;
	    padding: 50px 20px 0 20px;
		
		@media ( min-width: 992px ) {
			padding: 0;
		    padding-left: 360px;
		    padding-right: 50px;
		    padding-bottom: 50px;
		    
	        background-image: url('../img/back-content.png');
	        background-repeat:no-repeat;
	        background-position: 270px 0;
		}
		@media ( min-width: 1500px ) {
			max-width: 1425px;
		}
	}	
	    
	@media ( max-width: 991px ) {
		.top-content { display: none; }
	}
}