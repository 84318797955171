.page-index {


	@media ( min-width: 1650px ) {
				
		.page-container {
			display: flex;
			flex-direction: row;
			flex: 1;
		}
		
		.aside-container {
			flex: 0 0 45%;
		    position: relative;
		    padding-left: 120px;
		    padding-right: 50px;
		}
	    .aside-container-bg {	
	        position: absolute;
	        top: 0;
	        left: -300px;
	        z-index: 21;
	        width: ~"calc( 100% + 300px )";
	        height: 100%;
	        -webkit-box-shadow: 0 0 12px 5px rgba(0,0,0,0.1);
	        box-shadow: 0 0 12px 5px rgba(0,0,0,0.1);
	        background-color: white;
	        transform: skewX(8deg);
	        
	        &:before {
		        content:'';
		        position:absolute;
		        right: 0;
		        top: -10px;
		        width: 753px;
		        height: 258px;
		        background-image: url('../img/back-content.png');
		        background-repeat:no-repeat;
		        background-size: contain;
		        transform: skewX(-8deg);
		    }
	    }	    
	    .aside-container-content {
	        position: relative;
	        z-index: 22;
	        display: flex;
	        flex-direction: column;
	        height: 100%;
	    }
	    
	    .bloc-scroll {
	        overflow: auto;
	        flex: 1;
	        padding-right:30px;
	        margin-bottom:40px;
	    }
	    
	
	    .main-container {
	    	display: flex;
	    	flex-direction: column;
	        flex:1;
	        margin-top: 0;
		    padding:0 20px;
	        padding-left:100px;
	    }
	}
	
	
	@media ( min-width: 992px) and ( max-width: 1650px ) {		
		.page-container {
			display: flex;
			flex-direction: column;
			flex: 1;
		    padding-top: 115px;
		    padding-left: 360px;
		    padding-right: 50px;
		    
	        background-image: url('../img/back-content.png');
	        background-repeat:no-repeat;
	        background-position: 270px 0;
		}		
	
	    .aside-container {
	    	order: 1;
	    	margin-top: 50px;
	    }
	}
	
	@media ( max-width: 1650px ) {		
		.top-content { display: none; }
	}
	
	@media ( max-width: 991px ) {
		.page-container {
		    display: flex;
		    flex-direction: column;
		    padding: 0 20px;
		}
	    .aside-container {
	    	margin-top: 50px;
	    	order: 1; 
	    }
	    .main-container {
	    	margin-top: 50px;
	    }
	}

}