

.list-jobs{
    padding:0;
    margin:20px 0 0;
    list-style: none;
}


.list-jobs-item{

}

.list-jobs-header span{
    display:block;
    text-align:left;
    margin: 10px 0;
    letter-spacing: .1em;
}


.list-jobs-item-intitule,
.list-jobs-item-lieu,
.list-jobs-item-type,
.list-jobs-item-remuneration{
    padding-left:20px;
    padding-right:0;
}
.list-jobs-item-link{
    display:block;
    padding:10px 0;
    border-radius: 10px;
    border-bottom: 10px solid #fff;
    background-color: #f8f8f8;
    cursor: pointer;
    color:#282828;
    font-family: 'Rubik';
}

.list-jobs-item-link:hover{
    background-color:#dddf4b;
    color:#fff;
    text-decoration:none;
}

.list-jobs-item span{
    display:inline-block;
}

.list-jobs-item-lieu{
    width:220px;
}
.list-jobs-item-type{
    width:200px;
}

.list-jobs-item-remuneration{
    width:150px;
}



/* Responsive */
/* ------------------------------------------- */


@media ( min-width: 768px ) and ( max-width: 1275px ) {

    .list-jobs-item-intitule{
        width:150px;
    }
    .list-jobs-item-lieu{
        width:150px;
    }
    .list-jobs-item-type{
        width:150px;
    }    
    .list-jobs-item-remuneration{
        width:150px;
    }
}

@media ( max-width: 768px ) {

    .list-jobs-header { display: none !important; }

}
