
/* Boutons */
/* ------------------------------------------- */

.btn-primary{
    font-weight:bold;
    color:#fff;
    border:none;
    text-transform:uppercase;
    position:relative;
    margin-bottom:20px;
    padding:10px 20px 10px 50px;
    background-color: #c34724;
    border-radius: 30px;
    overflow: hidden;
}

.btn-primary::after{
    content:'';
    position:absolute;
    left:0;
    top:0;
    border: 4px solid #c34724;
    width:100%;
    height:100%;
    border-radius: 30px;
    transition: all 200ms;
}

.btn-primary > svg{
    position:absolute;
    left:2px;
    width:30px;
}

.btn-primary:hover{
    background-color:#000;
}

.btn-primary:hover::after {
    border-color: #000;
}