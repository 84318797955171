
@import 'fonts.less';
@import 'menu.less';
@import 'btn.less';
@import 'form.less';
@import 'table.less';
/* Wrapper */
/* ------------------------------------------- */
.wrapper {
    background-color: white;
}

/* Textes */
/* ------------------------------------------- */

html{
    
}

body {
    font-family: 'Rubik';
    font-weight:normal;
    color:#232323;
    font-size: 0.875rem;
}

h2{
    font-weight:bold;
    text-transform: uppercase;
    color:#c34724;
    font-size:1.5625rem;
    padding: 0;
    margin: 0;
}

h3{
    font-weight:normal;
    font-size:1.25rem;
    color:#dddf4b;
    text-transform: uppercase;
    padding: 0;
    margin: 0;
}

h4 {
    font-weight: 100;
    font-size: 1.1rem;
}

a {
    color:#c34724;
    &:hover, &:active, &:focus {
        color:#c34724;
    }
}

p{
    margin-top:20px;
}

li{
    font-weight:normal;
    color:#232323;
}
.list-inline-item:not(:last-child) { margin-right: 0; }

/* LINKS */

.page-link{
    color:#282828;
}

.page-link:hover{
    color:#fff;
    background-color: #c34724;
    border-color: #c34724;
}

.page-item.active .page-link{
    color:#fff;
    background-color: #c34724;
    border-color: #c34724;
}

/* BANNIERES */

.top-content {
	flex: 0 0 130px;
    position: relative;
    width: 30px;
    height: 130px;
    margin-bottom: 20px;
    background-color: #c34724;
    -webkit-border-radius: 0 0px 30px 30px;
    -moz-border-radius: 0 0px 30px 30px;
    border-radius: 0 0px 30px 30px;
}

.top-content::after {
    content: '';
    position: absolute;
    border: 4px solid #c34724;
    width: 30px;
    height: 130px;
    -webkit-border-radius: 0 0px 30px 30px;
    -moz-border-radius: 0 0px 30px 30px;
    border-radius: 0 0px 30px 30px;
}

.top-content > svg {
    position: absolute;
    bottom: 2px;
}

.top-content > svg > path{
    fill: #fff;
}


@import 'jobs.less';

@import 'index.less';
@import 'content.less';

//@import "responsive.less";