
/* Menu */
/* ------------------------------------------- */

.page-content .main-menu{
    z-index: 1;
}
.navbar-light .navbar-toggler {
    position: absolute;
    right: 20px;
    top: 20px;
}

.list-nav-item {
    padding: 85px 0 0 30px;
}

.nav-item {
    padding: 4px 0;
}

.nav-item a {
    font-family: 'Rubik';
    font-weight: bold;
    font-style: normal;
    font-size: 0.8461rem;
    text-transform: uppercase;
    color: #232323;
}

.nav-item a.active {
    color: #c5471d;
    position: relative;
}

.nav-item a.active::before {
    content: '';
    background-image: url('../img/hover-nav.png');
    position: absolute;
    height: 53px;
    width: 224px;
    left: -70px;
    bottom: -17px;
}

.list-ssnav-item {
    padding: 20px 0 0 30px;
}

.ssnav-item .active {
	text-decoration: underline;
}

.ssnav-item a {
    font-weight: 300;
    color: #989898;
    font-size: 0.75rem;
    text-transform: uppercase;
}



/* Responsive */
/* ------------------------------------------- */

@media (max-width:576px){

    .navbar-brand img{
        max-width: 150px;
    }

    .main-menu {
        padding: 20px;
        text-align: center;
    }


}

@media (max-width:991px) {

    .main-menu {
        padding: 20px;
        text-align: center;
    }

    .list-nav-item{
        padding: 10px 0 0;
    }
    
	.list-ssnav-item {
	    padding: 20px 0 0 0;
	}

    .navbar-collapse{
        border-top: 1px solid #ccc;
        border-bottom: 1px solid #ccc;
        margin: 30px 0;
    }

    .nav-item a.active::before{
        content:none;
    }

}

@media (min-width:992px) {
    .main-menu {
        position: fixed;
        width: 230px;
        padding:0;
        height:100%;
    }
    .main-menu-bg {
        position: absolute;
        top: 0;
        left: -72px;
        z-index: 31;
        width: 150%;
        height: 100%;

        -webkit-box-shadow: 0 0 12px 5px rgba(0,0,0,0.1);
        box-shadow: 0 0 12px 5px rgba(0,0,0,0.1);
        background-color: white;
        transform : skewX(-6deg);
    }
    .main-menu-content {
        position: relative;
        z-index: 32;
        padding: 40px 0 0 40px;
        width: 250px;
    }

    .main-menu.full-content{
        position:relative;
    }



}

@media ( min-width: 1650px ) {
    .page-index .main-menu {
        position: relative;
    }
}