
/* Fomrulaires */
/* ------------------------------------------- */

.form-control {
    font-size: 0.875rem;

    &:focus {
        border-color: #eebaac;
        box-shadow: 0 0 0 0.2rem rgba(239,186,172,.45);
    }
}

.custom-file-label,
.custom-file-label::after,
.custom-control-label {
    line-height: 1.9; 
}
.custom-control-input:focus~.custom-control-label::before {    
    box-shadow: 0 0 0 1px #fff, 0 0 0 0.2rem rgba(239,186,172,.45);
}
.custom-checkbox .custom-control-input:checked~.custom-control-label::before {
    background-color: #c34724;
}